import React from "react";
import { COLORS } from "../../../utills/Constants";

const AudioIcon = ({ color = COLORS.PRIMARY_TEXT_COLOR }) => (
    <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" fill={color}>
        <path d="m26.609 2c-0.66406 0-1.3047 0.28125-1.7539 0.76562l-12.855 13.234v18l12.855 13.234c0.44922 0.48828 1.0898 0.76562 1.7539 0.76562 1.3164 0 2.3906-1.0742 2.3906-2.3906v-41.219c0-1.3164-1.0742-2.3906-2.3906-2.3906zm13.086 8.4219-1.1211 1.6523c4.4336 2.6055 7.4258 7.418 7.4258 12.926 0 5.5078-2.9922 10.32-7.4258 12.926l1.1211 1.6523c4.9648-2.9688 8.3047-8.3828 8.3047-14.578 0-6.1953-3.3398-11.609-8.3047-14.578zm-3.3711 4.957-1.1328 1.6641c2.8555 1.5117 4.8086 4.5078 4.8086 7.957s-1.9531 6.4453-4.8086 7.957l1.1328 1.6641c3.3828-1.8828 5.6758-5.4844 5.6758-9.6211s-2.293-7.7383-5.6758-9.6211zm-31.324 0.62109c-1.6523 0-3 1.3477-3 3v12c0 1.6523 1.3477 3 3 3h5v-18zm27.922 4.3867-1.168 1.7227c1.2891 0.33594 2.2461 1.4961 2.2461 2.8906s-0.95703 2.5547-2.2461 2.8906l1.168 1.7227c1.8047-0.75781 3.0781-2.5391 3.0781-4.6133s-1.2734-3.8594-3.0781-4.6133z" />
    </svg>
);

export default AudioIcon;
