import React from "react";
import { COLORS } from "../../../utills/Constants";

const MediumIcon = ({ color = COLORS.TEXT_COLOR }) => (
    <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" fill={color}>
        <path d="m18 41.578c0 0.89062-0.47656 1.4219-1.1523 1.4219-0.23828 0-0.50391-0.066406-0.78516-0.20703l-10.898-5.5195c-0.64062-0.32422-1.1641-1.1836-1.1641-1.9062v-27.055c0-0.71484 0.37891-1.1367 0.92188-1.1367 0.19141 0 0.40625 0.050782 0.62891 0.16406l0.38281 0.19531h0.003906l12.012 6.082c0.019531 0.011718 0.035156 0.03125 0.050781 0.046875zm12.586-32.695 0.73438-1.207c0.26172-0.42969 0.74219-0.67578 1.2305-0.67578 0.11328 0 0.23047 0.015625 0.34375 0.046875 0.085938 0.019531 0.17578 0.050781 0.26953 0.097656l12.668 6.4141c0.003907 0 0.003907 0.003906 0.003907 0.003906l0.011718 0.003906c0.007813 0.003907 0.007813 0.015625 0.015625 0.019532 0.0625 0.050781 0.089844 0.13672 0.046875 0.20703l-12.617 20.766-1.2891 2.1172-8.3594-16.371zm-10.586 21.723v-13.043l8.9805 17.594-8.0781-4.0898zm26 10.973c0 0.83594-0.49609 1.3125-1.2266 1.3125-0.32812 0-0.70312-0.09375-1.0977-0.29297l-1.8086-0.91797-8.0781-4.0898 12.211-20.094z" />
    </svg>
);

export default MediumIcon;
