import React from "react";
import { COLORS } from "../../../utills/Constants";

const FacebookIcon = ({ color = COLORS.TEXT_COLOR }) => (
    <svg viewBox="0 0 172 172" xmlns="http://www.w3.org/2000/svg" fill={color}>
        <path d="m110.08 37.84h17.2c1.8989 0 3.44-1.5411 3.44-3.44v-23.175c0-1.8026-1.3863-3.3024-3.182-3.4297-5.473-0.38872-16.165-0.91504-23.853-0.91504-21.125 0-34.885 12.659-34.885 35.666v22.814h-24.08c-1.8989 0-3.44 1.5411-3.44 3.44v24.08c0 1.8989 1.5411 3.44 3.44 3.44h24.08v65.36c0 1.8989 1.5411 3.44 3.44 3.44h24.08c1.8989 0 3.44-1.5411 3.44-3.44v-65.36h24.844c1.7544 0 3.2267-1.3175 3.4194-3.0616l2.6763-24.08c0.22704-2.0365-1.3691-3.8184-3.4194-3.8184h-27.52v-17.2c0-5.7001 4.6199-10.32 10.32-10.32z" />
    </svg>
);

export default FacebookIcon;
