import React from "react";
import { COLORS } from "../../../utills/Constants";

const InstagramIcon = ({ color = COLORS.TEXT_COLOR }) => (
    <svg viewBox="0 0 172 172" xmlns="http://www.w3.org/2000/svg" fill={color}>
        <path d="m22.844 0c-12.906 0-22.844 9.9382-22.844 22.844v126.31c0 12.906 9.9382 22.844 22.844 22.844h126.31c12.906 0 22.844-9.9382 22.844-22.844v-126.31c0-12.906-9.9382-22.844-22.844-22.844zm0 14.333h126.31c5.0111 0 8.5104 3.4994 8.5104 8.5104v34.49h-36.281c-9.0423-8.9023-21.528-14.333-35.385-14.333s-26.343 5.431-35.385 14.333h-36.281v-34.49c0-5.0111 3.4994-8.5104 8.5104-8.5104zm101.23 7.1667c-1.4277 0-2.2396 0.81185-2.2396 2.2396v24.188c0 1.4277 0.81185 2.2396 2.2396 2.2396h24.188c1.4277 0 2.2396-0.81185 2.2396-2.2396v-24.188c0-1.4277-0.81185-2.2396-2.2396-2.2396zm-38.073 35.833c20.072 0 35.833 15.761 35.833 35.833s-15.761 35.833-35.833 35.833-35.833-15.761-35.833-35.833 15.761-35.833 35.833-35.833zm0 14.333c-2.1556 0-4.3392 0.67188-6.4948 0.67188 3.5833 1.4277 6.4948 5.7669 6.4948 10.078 0 5.7389-5.0111 10.75-10.75 10.75-4.3112 0-7.9225-2.9115-10.078-6.4948 0 2.1556-0.67187 4.3392-0.67187 6.4948 0 12.178 9.3223 21.5 21.5 21.5s21.5-9.3223 21.5-21.5-9.3223-21.5-21.5-21.5z" />
    </svg>
);

export default InstagramIcon;
