import React from "react";
import { COLORS } from "../../../utills/Constants";

const TwitterIcon = ({ color = COLORS.TEXT_COLOR }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={color}>
        <path d="m24 4.3008c-0.89844 0.39844-1.8008 0.69922-2.8008 0.80078 1-0.60156 1.8008-1.6016 2.1992-2.7031-1 0.60156-2 1-3.0977 1.2031-1-1-2.3008-1.6016-3.6992-1.6016-2.7031 0-4.9023 2.1992-4.9023 4.8984 0 0.40234 0 0.80078 0.10156 1.1016-4.1016-0.19922-7.6992-2.1016-10.102-5.1016-0.5 0.70312-0.69922 1.6016-0.69922 2.5 0 1.7031 0.89844 3.2031 2.1992 4.1016-0.80078-0.10156-1.5977-0.30078-2.1992-0.60156v0.10156c0 2.3984 1.6992 4.3984 3.8984 4.8008-0.39844 0.097657-0.79688 0.19922-1.2969 0.19922-0.30078 0-0.60156 0-0.90234-0.10156 0.60156 2 2.4023 3.4023 4.6016 3.4023-1.6992 1.3008-3.8008 2.0977-6.1016 2.0977-0.39844 0-0.80078 0-1.1992-0.097657 2.1992 1.3984 4.8008 2.1992 7.5 2.1992 9.1016 0 14-7.5 14-14v-0.60156c1-0.69922 1.8008-1.5977 2.5-2.5977" />
    </svg>
);

export default TwitterIcon;
